import { localhost, moonbaseAlpha } from 'wagmi/chains'

import type { Register } from '@app/local-contracts'
import { makeLocalhostChainWithEns } from '@app/utils/chains/makeLocalhostChainWithEns'

export const deploymentAddresses = JSON.parse(
  process.env.NEXT_PUBLIC_DEPLOYMENT_ADDRESSES || '{}',
) as Register['deploymentAddresses']

export const localhostWithEns = makeLocalhostChainWithEns<typeof localhost>(
  localhost,
  deploymentAddresses,
)

export const moonbaseAlphaWithEns = {
  ...moonbaseAlpha,
  contracts: {
    ensBaseRegistrarImplementation: {
      address: '0xa68b1cf9E674CC259967Ae56d614c2946B1577E9',
    },
    ensBulkRenewal: {
      address: '0x4ce5Ef0eB798fA3279210076A6F44bf4930cc87B',
    },
    ensDnsRegistrar: {
      address: '0x9980409ED28b6b70aB8b7C29fBd1713543Ae7A4C',
    },
    ensDnssecImpl: {
      address: '0xc9787487D83739eC25ce27176dA127f4205B8804',
    },
    ensEthRegistrarController: {
      address: '0x77880cAeBCed70DD4E24D79f6BC96Ad405f0E158',
    },
    ensNameWrapper: {
      address: '0xab28F71d0F8deC6c51D91Ce6d478B73fA7088Fc4',
    },
    ensPublicResolver: {
      address: '0x99fb3e379436Ba41960f868f836634435CaB99f9',
    },
    ensRegistry: {
      address: '0xE6387C375b0291929C9B3f51f227F9aFE1Db558e',
    },
    ensReverseRegistrar: {
      address: '0x06a0924FC7C9d06c05B3e539c6453958C0F94a36',
    },
    ensUniversalResolver: {
      address: '0x796340e5662834967F6d9B1B82E6aCa9EF07bd66',
    },
    ensMetadataService: {
      address: '0x452606a3176441753776766DBb51AE85136f4148',
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  subgraphs: {
    ens: {
      url: 'https://api.studio.thegraph.com/query/89572/3ns/version/latest',
    },
  },
}

export const chainsWithEns = [moonbaseAlphaWithEns] as const

export const getSupportedChainById = (chainId: number | undefined) =>
  chainId ? chainsWithEns.find((c) => c.id === chainId) : undefined

export type SupportedChain = typeof moonbaseAlphaWithEns | typeof localhostWithEns
